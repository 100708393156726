import React, { useState, useEffect } from 'react';
import Map from '../Map';
import MapModal from '../MapModal';

const MapContainer = ({ data }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

    useEffect(() => {
        const checkDevice = () => {
            setIsMobileOrTablet(window.innerWidth <= 1024);
        };

        checkDevice();
        window.addEventListener('resize', checkDevice);

        return () => window.removeEventListener('resize', checkDevice);
    }, []);

    if (!isMobileOrTablet) {
        return <Map data={data} isMobileView={false} />;
    }

    return (
        <>
            <div 
                onClick={() => setIsModalOpen(true)}
                className="relative cursor-pointer"
            >
                <Map data={data} isMobileView={false} />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-20">
                    <span className="rounded-full bg-white px-4 py-2 text-sm font-semibold text-gray-800">
                        Cliquer ici pour afficher en plein écran
                    </span>
                </div>
            </div>

            <MapModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <Map data={data} isMobileView={true} />
            </MapModal>
        </>
    );
};

export default MapContainer; 