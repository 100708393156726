/**
 * Calculates positions for petals around the center
 * @param {number} index - Current petal index
 * @param {number} total - Total number of petals
 * @param {number} width - Map width
 * @param {number} height - Map height
 * @returns {{x: number, y: number}} Coordinates for the petal
 */
export const getPetalPosition = (index, total, width, height) => {
    // Calculate angle for even distribution
    const angle = (2 * Math.PI * index) / total;

    // Set ellipse dimensions (semi-major and semi-minor axes)
    const radiusX = width * 0.55; // Horizontal radius
    const radiusY = height * 0.55; // Vertical radius

    // Calculate position on pure ellipse
    const x = radiusX * Math.cos(angle);
    const y = radiusY * Math.sin(angle);

    return { x, y };
};

/**
 * Calculates positions for links within a petal using multiple ellipses
 * @param {number} index - Current link index
 * @param {number} total - Total number of links
 * @param {number} width - Petal width
 * @param {number} height - Petal height
 * @returns {{x: number, y: number, fontSize: number}} Coordinates and font size for the link
 */
export const getLinkPosition = (index, total, width, height) => {
    // Define ellipse radiuses and starting angles
    const ellipses = {
        inner: {
            radiusX: width * 0.2,
            radiusY: height * 0.2,
            maxLinks: 5,
            fontSize: 0.65,
            startAngle: 0, // Start at 0 degrees
        },
        middle: {
            radiusX: width * 0.35,
            radiusY: height * 0.35,
            maxLinks: 7,
            fontSize: 0.6,
            startAngle: Math.PI / 3, // Start at 60 degrees
        },
        outer: {
            radiusX: width * 0.45,
            radiusY: height * 0.45,
            fontSize: 0.55,
            startAngle: Math.PI / 6, // Start at 30 degrees
        },
    };

    // Determine link distribution based on total number
    let ellipseConfig;
    let ellipseIndex;
    let totalInEllipse;

    if (total <= 5) {
        // Use only inner ellipse for 4 or fewer links
        ellipseConfig = ellipses.inner;
        ellipseIndex = index;
        totalInEllipse = total;
    } else if (total <= 12) {
        // Use inner and middle ellipses
        if (index < 5) {
            ellipseConfig = ellipses.inner;
            ellipseIndex = index;
            totalInEllipse = 5;
        } else {
            ellipseConfig = ellipses.middle;
            ellipseIndex = index - 5;
            totalInEllipse = total - 5;
        }
    } else {
        // Use all three ellipses for more than 8 links
        if (index < 5) {
            ellipseConfig = ellipses.inner;
            ellipseIndex = index;
            totalInEllipse = 5;
        } else if (index < 12) {
            ellipseConfig = ellipses.middle;
            ellipseIndex = index - 5;
            totalInEllipse = 5;
        } else {
            ellipseConfig = ellipses.outer;
            ellipseIndex = index - 12;
            totalInEllipse = total - 12;
        }
    }

    // Calculate position on the appropriate ellipse
    const angleStep = (2 * Math.PI) / totalInEllipse;
    const baseAngle = angleStep * ellipseIndex;

    // Calculate final angle with starting offset
    const angle = baseAngle + ellipseConfig.startAngle;

    // Calculate coordinates
    const x = ellipseConfig.radiusX * Math.cos(angle);
    const y = ellipseConfig.radiusY * Math.sin(angle);

    return {
        x,
        y,
        fontSize: ellipseConfig.fontSize,
    };
};
