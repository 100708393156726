import React, { useState, useRef, useEffect, useMemo } from "react";
import Petal from "../Petal";
import { getPetalPosition } from "../../utils/positionUtils";

const Map = ({ data, isMobileView }) => {
    const [scale, setScale] = useState(isMobileView ? 0.5 : 1);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [lastTouchDistance, setLastTouchDistance] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [lastTouchPos, setLastTouchPos] = useState(null);
    const frameRef = useRef(null);

    // Get unique themes
    const themes = [...new Set(data.map((item) => item.theme))];
    const centerTheme = "Faire corps";
    const surroundingThemes = themes.filter((theme) => theme !== centerTheme);

    // Adjusted dimensions to fit grid layout
    const frameWidth = "100%"; // Will fit container width
    const frameHeight = 750; // Reduced height
    const mapWidth = 750; // Reduced for better petal spacing
    const mapHeight = 500; // Reduced for better petal spacing

    // Calculate petal positions once and memoize them
    const petalPositions = useMemo(() => {
        return surroundingThemes.map((_, index) =>
            getPetalPosition(
                index,
                surroundingThemes.length,
                mapWidth,
                mapHeight,
            ),
        );
    }, [surroundingThemes.length, mapWidth, mapHeight]);

    const handleWheel = (e) => {
        const rect = frameRef.current.getBoundingClientRect();
        const isInsideFrame =
            e.clientX >= rect.left &&
            e.clientX <= rect.right &&
            e.clientY >= rect.top &&
            e.clientY <= rect.bottom;

        if (isInsideFrame) {
            e.preventDefault();
            const zoomSensitivity = 0.1;
            const newScale = Math.max(
                0.5,
                Math.min(
                    4,
                    scale + (e.deltaY > 0 ? -zoomSensitivity : zoomSensitivity),
                ),
            );
            setScale(newScale);
        }
    };

    useEffect(() => {
        const frame = frameRef.current;
        if (!frame) return;

        const preventScroll = (e) => {
            const rect = frame.getBoundingClientRect();
            const isInsideFrame =
                e.clientX >= rect.left &&
                e.clientX <= rect.right &&
                e.clientY >= rect.top &&
                e.clientY <= rect.bottom;

            if (isInsideFrame) {
                e.preventDefault();
            }
        };

        frame.addEventListener("wheel", preventScroll, { passive: false });

        return () => {
            frame.removeEventListener("wheel", preventScroll);
        };
    }, []);

    const getTouchDistance = (touches) => {
        return Math.hypot(
            touches[0].clientX - touches[1].clientX,
            touches[0].clientY - touches[1].clientY,
        );
    };

    const handleTouchStart = (e) => {
        if (e.touches.length === 2) {
            setLastTouchDistance(getTouchDistance(e.touches));
        } else if (e.touches.length === 1) {
            setIsDragging(true);
            setLastTouchPos({
                x: e.touches[0].clientX,
                y: e.touches[0].clientY,
            });
        }
    };

    const handleTouchMove = (e) => {
        e.preventDefault();

        if (e.touches.length === 2) {
            const newDistance = getTouchDistance(e.touches);
            if (lastTouchDistance) {
                const delta = newDistance - lastTouchDistance;
                const newScale = Math.max(
                    0.5,
                    Math.min(4, scale + delta * 0.01),
                );
                setScale(newScale);
            }
            setLastTouchDistance(newDistance);
        } else if (e.touches.length === 1 && isDragging && lastTouchPos) {
            const deltaX = e.touches[0].clientX - lastTouchPos.x;
            const deltaY = e.touches[0].clientY - lastTouchPos.y;

            if (isMobileView) {
                setPosition({
                    x: position.x + deltaY,
                    y: position.y - deltaX,
                });
            } else {
                setPosition({
                    x: position.x + deltaX,
                    y: position.y + deltaY,
                });
            }

            setLastTouchPos({
                x: e.touches[0].clientX,
                y: e.touches[0].clientY,
            });
        }
    };

    const handleTouchEnd = () => {
        setLastTouchDistance(null);
        setIsDragging(false);
        setLastTouchPos(null);
    };

    // Modified getPetalSize function with more dramatic scaling and logging
    const getPetalSize = (theme) => {
        const themeLinks = data.filter((item) => item.theme === theme).length;
        const baseWidth = theme === centerTheme ? 300 : 200;
        const baseHeight = theme === centerTheme ? 190 : 130;

        // More dramatic scaling: 50% to 150% based on links
        const scaleFactor = Math.min(Math.max(0.5, themeLinks / 3), 1.5);

        const size = {
            width: Math.round(baseWidth * scaleFactor),
            height: Math.round(baseHeight * scaleFactor),
        };

        console.log(
            `Theme: ${theme}, Links: ${themeLinks}, Scale: ${scaleFactor}`,
            size,
        );
        return size;
    };

    return (
        <div
            ref={frameRef}
            className="frame relative mx-auto overflow-hidden"
            style={{
                width: frameWidth,
                height: frameHeight,
                border: "2px solid black",
                cursor: isDragging ? "grabbing" : "grab",
            }}
            onWheel={handleWheel}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
            onMouseLeave={handleTouchEnd}
        >
            <div
                className="mind-map absolute"
                style={{
                    width: mapWidth,
                    height: mapHeight,
                    left: "50%",
                    top: "50%",
                    transform: `translate(-50%, -50%) translate(${position.x}px, ${position.y}px) scale(${scale})`,
                    transition: isDragging ? "none" : "transform 0.1s ease-out",
                }}
            >
                {/* Center Petal */}
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                    <Petal
                        theme={centerTheme}
                        data={data}
                        key={centerTheme}
                        ellipseWidth={300}
                        ellipseHeight={190}
                        scale={scale}
                        isCenter={true}
                    />
                </div>

                {/* Surrounding Petals */}
                {surroundingThemes.map((theme, index) => {
                    const position = petalPositions[index];

                    return (
                        <div
                            key={theme}
                            className="absolute"
                            style={{
                                left: `${position.x + mapWidth / 2}px`,
                                top: `${position.y + mapHeight / 2}px`,
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <Petal
                                key={theme}
                                theme={theme}
                                data={data}
                                ellipseWidth={200}
                                ellipseHeight={130}
                                scale={scale}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Map;
