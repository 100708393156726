import React from "react";
import { IconMapper } from "../../utils/iconMapper";

const DynamicIcon = ({ iconName }) => {
    const IconComponent = IconMapper[iconName]; // Trouve l'icône dans le mapper
    if (!IconComponent) {
        return null; // Rendre null si aucune icône ne correspond
    }

    return <IconComponent />; // Rendre le composant d'icône
};

export default DynamicIcon;
