import React, { useMemo } from "react";
import PetalLink from "../PetalLink";
import themeColors from "../../data/themeColors";

// Constants for size calculations
const BASE_PETAL_SIZE = 300; // Base size for petals with few links
const MAX_PETAL_GROWTH = 1.5; // Maximum size multiplier for petals with many links
const LINK_THRESHOLD = 20; // Number of links that triggers maximum petal size

const Petal = ({
    theme,
    data,
    ellipseWidth,
    ellipseHeight,
    scale = 1,
    isCenter,
}) => {
    const themeLinks = data.filter((item) => item.theme === theme);

    // Calculate petal size multiplier based on number of links
    const sizeMultiplier = useMemo(() => {
        const linkCount = themeLinks.length;
        if (linkCount <= 5) return 1;
        return Math.min(
            1 + (linkCount / LINK_THRESHOLD) * 0.5,
            MAX_PETAL_GROWTH,
        );
    }, [themeLinks.length]);

    // Adjust petal dimensions
    const adjustedWidth = ellipseWidth * sizeMultiplier;
    const adjustedHeight = ellipseHeight * sizeMultiplier;

    const linkPositions = useMemo(() => {
        if (!themeLinks.length) return [];

        const positions = [];
        const totalLinks = themeLinks.length;

        // Make font even smaller based on number of links
        const baseFontSize = Math.max(0.4, 1 - totalLinks * 0.07);

        // Adjust number of rows based on whether it's center petal
        const numRows = Math.ceil(Math.sqrt(totalLinks)) * (isCenter ? 7 : 5);

        // Calculate the maximum links per row (adjusted for more rows)
        const maxLinksPerRow =
            Math.ceil(totalLinks / (numRows / (isCenter ? 4 : 4))) * 2;

        let currentLink = 0;

        // For each row
        for (let row = 0; row < numRows && currentLink < totalLinks; row++) {
            // Skip every other position in each row
            const isEmptyRow = row % 2 === 1;
            if (isEmptyRow) continue;

            // Calculate how many actual links should be in this row
            const linksInThisRow = Math.min(
                Math.ceil(maxLinksPerRow / 2),
                totalLinks - currentLink,
            );

            // Adjust row height calculation for center petal
            const rowHeight = adjustedHeight / (numRows + (isCenter ? 2 : 2));
            const y = rowHeight * (row + 1);

            // Calculate horizontal spacing for this row
            const spacing = adjustedWidth / (maxLinksPerRow / 2 + 1);
            // Offset alternate rows by one position
            const isOffset = Math.floor(row / 2) % 2 === 1;
            const offsetX = isOffset ? spacing : 0;

            // Position links in this row
            for (
                let i = 0;
                i < linksInThisRow && currentLink < totalLinks;
                i++
            ) {
                const x = offsetX + spacing * (i * 2 + 1);

                // Calculate distance from center
                const centerX = adjustedWidth / 2;
                const centerY = adjustedHeight / 2;
                const dx = x - centerX;
                const dy = y - centerY;

                // Adjust ellipse boundary based on whether it's center petal
                const boundaryFactor = isCenter ? 0.81 : 0.64; // Larger area for center petal
                const isInside =
                    (dx * dx) / ((adjustedWidth * adjustedWidth) / 4) +
                        (dy * dy) / ((adjustedHeight * adjustedHeight) / 4) <=
                    boundaryFactor;

                if (isInside) {
                    positions.push({
                        x,
                        y,
                        fontSize: `${baseFontSize}rem`,
                    });
                    currentLink++;
                }
            }
        }

        // Adjust circular fallback pattern for remaining links
        if (currentLink < totalLinks) {
            const remainingLinks = totalLinks - currentLink;
            const angleStep = (2 * Math.PI) / remainingLinks;

            // Adjust radius for center petal
            const radiusFactor = isCenter ? 0.35 : 0.25;

            for (let i = 0; i < remainingLinks; i++) {
                const angle = i * angleStep;
                const radiusX = adjustedWidth * radiusFactor;
                const radiusY = adjustedHeight * radiusFactor;

                positions.push({
                    x: adjustedWidth / 2 + radiusX * Math.cos(angle),
                    y: adjustedHeight / 2 + radiusY * Math.sin(angle),
                    fontSize: `${baseFontSize}rem`,
                });
            }
        }

        return positions;
    }, [themeLinks.length, adjustedWidth, adjustedHeight, isCenter]);

    const titleOpacity = scale <= 1.5 ? 1 : Math.max(0, 2.5 - scale);

    return (
        <div
            className="petal relative"
            style={{
                width: adjustedWidth,
                height: adjustedHeight,
                backgroundColor: themeColors[theme] || "#F8F8F8",
                boxShadow: "0 0 20px rgba(0,0,0,0.1)",
                backdropFilter: "blur(5px)",
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "50%",
                overflow: "hidden",
                transition: "width 0.3s ease, height 0.3s ease",
            }}
        >
            <div
                className="font-hand theme-title absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-center font-bold text-gray-700 transition-opacity duration-300"
                style={{
                    fontSize: "2rem",
                    opacity: titleOpacity,
                    pointerEvents: titleOpacity < 0.1 ? "none" : "auto",
                    zIndex: 1,
                }}
            >
                {theme}
            </div>

            {themeLinks.map((link, index) => {
                const position = linkPositions[index] || {
                    x: 0,
                    y: 0,
                    fontSize: "0.8rem",
                };

                return (
                    <div
                        key={link.title}
                        className="absolute -translate-x-1/2 -translate-y-1/2 transform"
                        style={{
                            left: `${position.x}px`,
                            top: `${position.y}px`,
                            opacity: scale < 1.2 ? 0.7 : 1,
                            transition: "opacity 0.3s ease",
                        }}
                    >
                        <PetalLink
                            title={link.title}
                            link={link.url}
                            icon={link.icon}
                            logo={link.logo}
                            logoUrl={link.logoUrl}
                            logoLabel={link.logoLabel}
                            fontSize={position.fontSize}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default Petal;
