const themeColors = {
    "Faire corps": "#8ac6ba",
    "Santé intégrative": "#91c4f2",
    Ethique: "#8c97cc",
    "Soigner la mort": "#99999a",
    "Santé des femmes": "#e7ec9b",
    "Santé planétaire": "#bfda8e",
    "Service public": "#f2b37b",
    "Santé communautaire": "#da8ab0",
    "Désobéissance civile": "#de8a7b",
    "Soin des soignant·es": "#fbe383",
};

export default themeColors;
