import React from "react";
import DynamicIcon from "../DynamicIcon";

const PetalLink = ({
    title,
    link,
    icon,
    logo,
    logoUrl,
    fontSize,
    logoLabel,
}) => {
    const maxWidth = 160;

    const linkStyle = {
        fontSize,
        maxWidth: `${maxWidth}px`,
        whiteSpace: "nowrap", // Ensure all content stays on the same line
        overflow: "hidden", // Hide overflow if the text is too long
        textOverflow: "ellipsis", // Add ellipsis for overflowing text
        display: "flex",
        flexDirection: "row", // Align items horizontally
        alignItems: "center", // Vertically center items
        textAlign: "center",
        gap: "0.5em", // Add spacing between elements
        lineHeight: "1.2",
    };

    return (
        <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex text-gray-700 transition-colors duration-200 hover:text-gray-900"
            style={linkStyle}
        >
            {/* Icon */}
            <DynamicIcon iconName={icon} />

            {/* Title */}
            <span
                style={{ fontSize: `calc(${fontSize})` }}
                className="text-blue-800"
            >
                {title}
            </span>

            {/* Logo (if present) */}
            {logo && (
                <img
                    src={logoUrl}
                    alt={title}
                    label={logoLabel}
                    style={{
                        width: `calc(${fontSize} * 1.5)`,
                        height: `calc(${fontSize} * 1.5)`,
                        marginBottom: "0.1em",
                    }}
                />
            )}
        </a>
    );
};

export default PetalLink;
