// Fichier généré automatiquement - Ne pas modifier manuellement
export const data = [
    {
        theme: "Désobéissance civile",
        title: "Les défis du XXIème siècle : des enjeux systémiques",
        url: "https://www.youtube.com/watch?v=rbDIFEtaMqs",
        icon: "BiSolidCameraMovie",
        logo: false,
        logoUrl: "",
        logoLabel: "Vidéo",
    },
    {
        theme: "Désobéissance civile",
        title: "Le printemps du care",
        url: "https://leprintempsducare.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://leprintempsducare.org/wp-content/uploads/2021/04/cropped-logo.sansJauneni-noir.opac_-e1617400468965.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Désobéissance civile",
        title: "Scientifiques en rébellion",
        url: "https://scientifiquesenrebellion.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl: "https://scientifiquesenrebellion.fr/img/temporary-logo.svg",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Désobéissance civile",
        title: "Extinction Rebellion",
        url: "https://extinctionrebellion.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://media.licdn.com/dms/image/v2/C4E0BAQEAD7XGxLQ5iw/company-logo_200_200/company-logo_200_200/0/1651336385445?e=2147483647&v=beta&t=92tk-2VdF1idwH_TdNUKYj-9XnZFSLcH3wn6l8VeK50",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Désobéissance civile",
        title: "Riposte Alimentaire",
        url: "https://ripostealimentaire.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://ripostealimentaire.fr/wp-content/themes/ripostealimentaire/assets/img/Logo-RA_rectangle_2.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Désobéissance civile",
        title: "Les Soulèvements de la Terre",
        url: "https://lessoulevementsdelaterre.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://lessoulevementsdelaterre.org/_next/image?url=%2FB.png&w=1920&q=75",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Désobéissance civile",
        title: "Action Justice Climat",
        url: "https://actionjusticeclimat-paris.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://media.licdn.com/dms/image/v2/D4E0BAQEayn31lbn8mw/company-logo_200_200/company-logo_200_200/0/1714025961349/alternatibaparis_logo?e=2147483647&v=beta&t=uIpkVRxR-3MyK7jyrVZ5nouGP1HmaX_04-CAn2EjwLs",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Désobéissance civile",
        title: "Terres de luttes",
        url: "https://terresdeluttes.fr",
        icon: "GiWorld",
        logo: true,
        logoUrl:
            "https://terresdeluttes.fr/wp-content/uploads/2021/12/Logo-carre-1.png",
        logoLabel: "Carte",
    },
    {
        theme: "Désobéissance civile",
        title: "Paye ton burn-out militant",
        url: "https://payetonburnoutmilitant.fr/des-ressources/",
        icon: "FcReading",
        logo: true,
        logoUrl:
            "https://i0.wp.com/payetonburnoutmilitant.fr/wp-content/uploads/2024/12/des-ressources-credite.jpg?resize=1471%2C1536&ssl=1",
        logoLabel: "Livre, article",
    },
    {
        theme: "Ethique",
        title: "Espaces éthiques régionaux",
        url: "https://www.erebfc.fr/les-espaces-de-reflexion-ethique-regionaux/",
        icon: "GiWorld",
        logo: false,
        logoUrl: "",
        logoLabel: "Carte",
    },
    {
        theme: "Ethique",
        title: "Espace éthique IdF",
        url: "https://www.espace-ethique.org",
        icon: "FaUserPen",
        logo: false,
        logoUrl: "",
        logoLabel: "Société savante, chaire",
    },
    {
        theme: "Ethique",
        title: "Ethique, la vie en question",
        url: "https://revue-ethique.univ-gustave-eiffel.fr",
        icon: "FcReading",
        logo: false,
        logoUrl: "",
        logoLabel: "Livre, article",
    },
    {
        theme: "Ethique",
        title: "Chaire de philo",
        url: "https://chaire-philo.fr",
        icon: "FaUserPen",
        logo: true,
        logoUrl:
            "https://chaire-philo.fr/wp-content/uploads/2019/10/logo-chaire-1.jpg",
        logoLabel: "Société savante, chaire",
    },
    {
        theme: "Ethique",
        title: "GEFERS",
        url: "https://www.gefers.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl: "https://www.gefers.fr/wp-content/uploads/2021/09/LOGO.svg",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Ethique",
        title: "Centre sésame",
        url: "https://centre-sesame.fr",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://centre-sesame.fr/wp-content/uploads/2024/07/Sesame-Centre-de-Culture-Spirituelle_logo.png.webp",
        logoLabel: "Lieu",
    },
    {
        theme: "Faire corps",
        title: "France Tiers Lieux",
        url: "https://cartographie.francetierslieux.fr",
        icon: "GiWorld",
        logo: true,
        logoUrl:
            "https://cartographie.francetierslieux.fr/upload/communecter/organizations/5ec3b895690864db108b46b8/LOGOCommunecter.png",
        logoLabel: "Carte",
    },
    {
        theme: "Faire corps",
        title: "Transiscope",
        url: "https://transiscope.org/carte-des-alternatives/",
        icon: "GiWorld",
        logo: true,
        logoUrl:
            "https://transiscope.org/wp-content/uploads/2018/09/Transiscope-alt2-blanc-1.svg",
        logoLabel: "Carte",
    },
    {
        theme: "Faire corps",
        title: "GT Santé Tiers-Lieux",
        url: "https://tiers-lieux.fr/?Santé",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://tiers-lieux.fr/files/PageTitre_Logo_site_bandeau150_20230203150545_20230203160555.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Faire corps",
        title: "Revue Pratiques",
        url: "https://pratiques.fr",
        icon: "FcReading",
        logo: true,
        logoUrl:
            "https://pratiques.fr/plugins/revue_pratiques/pratiques_squelettes/images/logo_pratiques.svg",
        logoLabel: "Livre, article",
    },
    {
        theme: "Faire corps",
        title: "EnCommuns",
        url: "https://www.encommuns.net",
        icon: "FcReading",
        logo: true,
        logoUrl: "https://www.encommuns.net/assets/images/logo.svg",
        logoLabel: "Livre, article",
    },
    {
        theme: "Faire corps",
        title: "InterHop",
        url: "https://interhop.org",
        icon: "GrPersonalComputer",
        logo: true,
        logoUrl:
            "https://interhop.org/assets/images/full_interhop_white_logopng.png",
        logoLabel: "Numérique",
    },
    {
        theme: "Faire corps",
        title: "La Fabrique des Soignants",
        url: "https://www.youtube.com/channel/UC5Jw_vLWT5XpgkDBeGZ4PFA",
        icon: "PiEyesFill",
        logo: true,
        logoUrl:
            "https://media.licdn.com/dms/image/v2/D560BAQGska2XoOKHjg/company-logo_200_200/company-logo_200_200/0/1681829749808?e=2147483647&v=beta&t=FJd-Y992LtY7zfZuVwzFsvfUrG-03XfKQ0pTXa6bfBY",
        logoLabel: "Media",
    },
    {
        theme: "Faire corps",
        title: "Wébinaire la Fabrique des santés",
        url: "https://www.fabsan.cc/faire-tiers-lieux-en-sante",
        icon: "PiEyesFill",
        logo: true,
        logoUrl:
            "https://custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_300,w_300,f_auto,q_100/3323786/651536_218672.png",
        logoLabel: "Media",
    },
    {
        theme: "Faire corps",
        title: "Lowpital",
        url: "https://lowpital.care",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://res2.weblium.site/res/604dd9f30e794e0021c848ff/604dda3891b5de00218c2424_optimized_467.webp",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Faire corps",
        title: "Répertoire de podcasts santé",
        url: "https://www.podcast-sante.com",
        icon: "HiMicrophone",
        logo: true,
        logoUrl:
            "https://cdn.prod.website-files.com/61cc9bb14b268d3549058f93/6284b930d9c25c2eee4812b1_Podcast%20Santé.svg",
        logoLabel: "Podcast",
    },
    {
        theme: "Faire corps",
        title: "Les Transformateurs",
        url: "https://lowpital.care/podcast",
        icon: "HiMicrophone",
        logo: true,
        logoUrl:
            "https://s3-us-west-2.amazonaws.com/anchor-generated-image-bank/production/podcast_uploaded_nologo400/13883548/13883548-1617214285996-00c2d2b5dc022.jpg",
        logoLabel: "Podcast",
    },
    {
        theme: "Faire corps",
        title: "Facteurs humains en santé",
        url: "https://facteurshumainsensante.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://facteurshumainsensante.org/wp-content/uploads/2021/01/cropped-Logo-FHS.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Faire corps",
        title: "Soignons Humain",
        url: "https://www.soignonshumain.com",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl: "https://www.soignonshumain.com/s/misc/logo.png?t=1729915713",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Faire corps",
        title: "L'humain au coeur du soin",
        url: "https://humainaucoeurdusoin.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://humainaucoeurdusoin.fr/wp-content/uploads/2023/11/logo-humain-au-coeur-du-soin.svg",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Faire corps",
        title: "Culture santé",
        url: "https://culturesante.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://culturesante.org/wp-content/uploads/2019/09/logo-header_v3.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Faire corps",
        title: "France Assos Santé",
        url: "https://www.france-assos-sante.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "??? https://www.france-assos-sante.org/wp-content/uploads/2018/11/Version-blanc-Logo-National-01.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Faire corps",
        title: "APAJH",
        url: "https://www.apajh.org/#/",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://www.apajh.org/uploads/2024/04/cropped-cropped-Logo-APAJH-JO-1.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Faire corps",
        title: "La Maison Perchée",
        url: "https://www.maisonperchee.org",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://images.prismic.io/mpnext/90400627-6d93-4b1b-81cb-81c74797f3c3_Core+Team+%282%29.png?auto=compress,format&rect=0,0,625,625&w=126&h=126",
        logoLabel: "Lieu",
    },
    {
        theme: "Faire corps",
        title: "Mūsae",
        url: "https://musae-tomorrow.com",
        icon: "PiEyesFill",
        logo: true,
        logoUrl:
            "https://musae-tomorrow.com/wp-content/uploads/2021/01/musae_logo-1.png",
        logoLabel: "Media",
    },
    {
        theme: "Faire corps",
        title: "Petite Mû",
        url: "https://www.petitemu.fr",
        icon: "PiEyesFill",
        logo: true,
        logoUrl:
            "https://cdn.prod.website-files.com/65016c6e0d2dc18b9d59f628/650174dac8bf8aa9d4e41db4_cropped-logo-horizontal-1536x671-p-500.png",
        logoLabel: "Media",
    },
    {
        theme: "Faire corps",
        title: "Les jardins d'Haïti",
        url: "https://jardinsdhaiti.fr",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://jardinsdhaiti.fr/wp-content/uploads/2024/03/logo_cr.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Faire corps",
        title: "EHPAD Kersalic",
        url: "https://kersalic.com",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "??? https://kersalic.com/wp-content/uploads/2022/08/KERSALIC-Logo-white-150x150.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Faire corps",
        title: "AMA Diem",
        url: "https://www.amadiem.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://www.amadiem.fr/wp-content/uploads/2020/09/cropped-NOUVEAU-LOGO-ROND-BLANC-plus-grand-5.png",
        logoLabel: "Collectif, association et lieu",
    },
    {
        theme: "Faire corps",
        title: "Village landais Alzheimer",
        url: "https://villagealzheimer.landes.fr",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://villagealzheimer.landes.fr/images/site_alzheimer/logos/Logo-vlhe-4coul-horizontal.jpg",
        logoLabel: "Lieu",
    },
    {
        theme: "Faire corps",
        title: "Toît Même",
        url: "https://www.toitmeme.com",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://static.wixstatic.com/media/b4a88a_f835bf50f8f14074ac37ad15c51f7393~mv2.png/v1/fill/w_412,h_190,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/b4a88a_f835bf50f8f14074ac37ad15c51f7393~mv2.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé communautaire",
        title: "Un autre soin est possible",
        url: "https://www.youtube.com/watch?v=TPn_edifie0",
        icon: "BiSolidCameraMovie",
        logo: false,
        logoUrl: "",
        logoLabel: "Vidéo",
    },
    {
        theme: "Santé communautaire",
        title: "Réseau des centres de santé communautaire",
        url: "https://reseau-cdsc.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://reseau-cdsc.fr/wp-content/uploads/2023/05/cropped-cropped-Logo-1.jpg",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé communautaire",
        title: "Banlieues Santé",
        url: "https://banlieues-sante.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://dev.banlieues-sante.org/wp-content/uploads/2023/02/cropped-logo-bs-1.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé communautaire",
        title: "A Vos Soins",
        url: "https://avossoins.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://avossoins.fr/wp-content/uploads/2023/09/LOGO-A-VOS-SOINS-BLANC.webp",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé communautaire",
        title: "La Place Santé - Saint-Denis",
        url: "http://acsbe.asso.fr",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "http://acsbe.asso.fr/wp-content/uploads/2024/02/Logo-CCSBE.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé communautaire",
        title: "Village 2 Santé - Eschirolles",
        url: "https://www.levillage2sante.fr",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://www.levillage2sante.fr/thumbnail-200/unknown-e1327534.jpg",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé communautaire",
        title: "La Case de Santé - Toulouse",
        url: "https://www.casedesante.org",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://image.jimcdn.com/app/cms/image/transf/dimension=230x10000:format=png/path/s398f4d2c04933ae9/image/i5f49c4b995f147be/version/1716210354/image.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé communautaire",
        title: "Le Château en santé - Marseille",
        url: "https://www.chateau-en-sante.org",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "http://www.chateau-en-sante.org/wp-content/uploads/2019/07/logo4.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé communautaire",
        title: "Human Santé - Montpellier",
        url: "https://www.human-sante.org",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://www.human-sante.org/wp-content/uploads/2019/01/logo-Human-sante-site-e1547227024558.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé communautaire",
        title: "La santé communautaire",
        url: "https://www.369editions.com/la-sante-communautaire-une-autre-politique-du-soin/",
        icon: "FcReading",
        logo: false,
        logoUrl: "",
        logoLabel: "Livre, article",
    },
    {
        theme: "Santé communautaire",
        title: "",
        url: "",
        icon: "",
        logo: false,
        logoUrl: "",
        logoLabel: "Livre, article",
    },
    {
        theme: "Santé des femmes",
        title: "Femmes de santé",
        url: "https://www.femmesdesante.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_300,w_300,f_auto,q_auto/1409725/327957_5793.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé des femmes",
        title: "Donner des ELLES à la santé",
        url: "https://donnerdeselles.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://donnerdeselles.org/wp-content/uploads/donner-des-elles-a-la-sante-logo.svg",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé des femmes",
        title: "Pour une MEUF",
        url: "https://www.pourunemeuf.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://www.pourunemeuf.org/wp-content/uploads/2020/01/logo-petit-menu-baseline-transparent.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé des femmes",
        title: "La maison des femmes - Saint-Denis",
        url: "https://www.lamaisondesfemmes.fr",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://www.lamaisondesfemmes.fr/assets-mdf/uploads/2024/01/logo-lmdf-stdenis-rvb-2023.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé des femmes",
        title: "Maison des femmes Gisèle Halimi - Rennes",
        url: "https://www.chu-rennes.fr/je-cherche/prise-en-charge-specifique/maison-des-femmes-gisele-halimi-1819.html",
        icon: "BsHouseFill",
        logo: false,
        logoUrl: "",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé des femmes",
        title: "25 Gisèle-Halimi - Rennes",
        url: "https://clermont-ferrand.fr/25-gisele-halimi",
        icon: "BsHouseFill",
        logo: false,
        logoUrl: "",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé intégrative",
        title: "Méditation et médecine : éloge du care ?",
        url: "https://www.youtube.com/playlist?list=PLRHVM3XoG5iFt9j8zgTzXXKnxlBodUhoG",
        icon: "BiSolidCameraMovie",
        logo: false,
        logoUrl: "",
        logoLabel: "Vidéo",
    },
    {
        theme: "Santé intégrative",
        title: "Mû médecine",
        url: "https://www.mumedecine.org",
        icon: "IoIosSchool",
        logo: true,
        logoUrl:
            "https://static.wixstatic.com/media/1dd2bf_df5c05997cd94e2bbbc77e61cac8c910~mv2.jpeg/v1/fill/w_1438,h_638,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/1dd2bf_df5c05997cd94e2bbbc77e61cac8c910~mv2.jpeg",
        logoLabel: "Formation, école",
    },
    {
        theme: "Santé intégrative",
        title: "CUMIC",
        url: "https://www.cumic.fr",
        icon: "FaUserPen",
        logo: true,
        logoUrl:
            "https://static.wixstatic.com/media/a30c6a_3c046f206b5a45648cbbc6b433c3b801~mv2.png/v1/fill/w_184,h_54,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/C_S_Nuit-Mandarine.png",
        logoLabel: "Société savante, chaire",
    },
    {
        theme: "Santé intégrative",
        title: "L'hirondelle bleue",
        url: "https://lhirondelle-bleue.fr",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://lhirondelle-bleue.fr/wp-content/themes/mytheme/assets/images/logo-wide.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé intégrative",
        title: "Institut Rafaël",
        url: "https://institut-rafael.fr/sante-integrative/",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://institut-rafael.fr/wp-content/uploads/2021/12/Logo-Rafael.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé intégrative",
        title: "NPIS ou Non Pharmacological Intervention Society [en fonction de la place]",
        url: "https://npisociety.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://npisociety.org/wp-content/uploads/2022/01/profils_institutionnel.jpg",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé intégrative",
        title: "Ohm santé intégrative",
        url: "https://www.ohmsanteintegrative.com",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://www.ohmsanteintegrative.com/wp-content/uploads/2023/03/OHM-SANTE-INTEGRATIVE-LOGO.jpg",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé intégrative",
        title: "Allié SANTÉ",
        url: "https://alliesante.net",
        icon: "PiEyesFill",
        logo: true,
        logoUrl:
            "https://alliesante.net/wp-content/uploads/2020/11/logo_allie_sante.jpg",
        logoLabel: "Media",
    },
    {
        theme: "Santé planétaire",
        title: "Le Jardin - Bron",
        url: "https://www.ubiclic.com/medecine-generale/bron/le-jardin",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://media.licdn.com/dms/image/v2/C4E0BAQHnvKlNwtXIiw/company-logo_200_200/company-logo_200_200/0/1677870820494/centre_de_sant_communautaire_et_plantaire_de_bron_logo?e=2147483647&v=beta&t=eaSFxdNxm3InagvoIdnJ4f9OkV7OlZ4ZMMXLaSV3fGA",
        logoLabel: "Lieu",
    },
    {
        theme: "Santé planétaire",
        title: "Alerte Médecins Pesticides",
        url: "https://alerte-medecins-pesticides.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://alerte-medecins-pesticides.fr/wp-content/uploads/2020/01/cropped-logo_amlp-1.jpg",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé planétaire",
        title: "Alliance Santé Planétaire",
        url: "https://santeplanetaire.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://dev.santeplanetaire.org/wp-content/uploads/2023/10/alliance-sante-planetaire-logo-vert-clair.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé planétaire",
        title: "Réseau RAFUE",
        url: "https://asso-rafue.com/?PagePrincipale",
        icon: "FaPeopleGroup",
        logo: false,
        logoUrl: "",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé planétaire",
        title: "Société Francophone de Santé Environnement",
        url: "https://www.sfse.org/accueil",
        icon: "FaUserPen",
        logo: true,
        logoUrl: "https://sfse.org/assets/sfse-logo.png",
        logoLabel: "Société savante, chaire",
    },
    {
        theme: "Santé planétaire",
        title: "Hameaux de Santé",
        url: "https://hameauxdesante.gogocarto.fr/map#/carte/@46.33,2.50,5z?cat=all",
        icon: "GiWorld",
        logo: true,
        logoUrl:
            "https://hameauxdesante.gogocarto.fr/uploads/hameauxdesante/images/config/untitled-artwork-50.png",
        logoLabel: "Carte",
    },
    {
        theme: "Santé planétaire",
        title: "Ecokinés",
        url: "https://www.ecokines.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://static.wixstatic.com/media/34434b_a86eb48cc9b440e78db2e0dac79ee5db~mv2.png/v1/fill/w_170,h_168,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/logo-label-ecokines.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé planétaire",
        title: "Ecoveto",
        url: "https://www.ecoveto.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://static.wixstatic.com/media/3e5184_f9c94ef67cbc44d0a2629c5926623103~mv2.png/v1/fill/w_184,h_55,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/Nouveau%20Logo%20ecoveto.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Santé planétaire",
        title: "Santé Planétaire",
        url: "https://www.ruedelechiquier.net/essais/386-sante-planetaire.html",
        icon: "FcReading",
        logo: false,
        logoUrl: "",
        logoLabel: "Livre, article",
    },
    {
        theme: "Santé planétaire",
        title: "Guide du cabinet écoresponsable",
        url: "https://www.presses.ehesp.fr/produit/guide-cabinet-de-sante-ecoresponsable/",
        icon: "FcReading",
        logo: false,
        logoUrl: "",
        logoLabel: "Livre, article",
    },
    {
        theme: "Santé planétaire",
        title: "Ooonehealth",
        url: "https://www.ooonehealth.fr",
        icon: "FcReading",
        logo: true,
        logoUrl:
            "https://static.wixstatic.com/media/ba19be_cdfcfdf497d94551aec5c7ff7f51dc1c~mv2.png/v1/fill/w_60,h_61,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/Illus-09_edited.png",
        logoLabel: "Livre, article",
    },
    {
        theme: "Service public",
        title: "La sociale",
        url: "http://www.lasociale.fr",
        icon: "BiSolidCameraMovie",
        logo: false,
        logoUrl: "",
        logoLabel: "Vidéo",
    },
    {
        theme: "Service public",
        title: "ATD Quart Monde",
        url: "https://www.atd-quartmonde.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://www.atd-quartmonde.fr/wp-content/uploads/elementor/thumbs/logo-atd-quart-monde-h-qo6ye6yk4mr1plq30g1td8nlggm7m4etenul3mv9i0.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Service public",
        title: "Collectif Inter Hôpitaux",
        url: "https://www.collectif-inter-hopitaux.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://images.squarespace-cdn.com/content/v1/5e356e21fb55d365939c3deb/1580582667700-KUY7H3H0F5FSEQ0ZRGYZ/Logo%2Bcentre%25CC%2581.jpg?format=1500w",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Service public",
        title: "Collectif Nos Services Publics",
        url: "https://nosservicespublics.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://nosservicespublics.fr/lib_qikhfHPciavWbnTn/wfk4scwibbcsrbhz.png?w=270",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Service public",
        title: "L'appel des Appels",
        url: "http://www.appeldesappels.org",
        icon: "FaPeopleGroup",
        logo: false,
        logoUrl: "",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Service public",
        title: "Ateliers pour la refondation du service public hospitalier et du soin",
        url: "https://www.ateliersrefondationhopitalpublic.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://www.ateliersrefondationhopitalpublic.org/wp-content/uploads/2024/07/logo-blanc-footer.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Service public",
        title: "Coordination défense santé",
        url: "https://coordination-defense-sante.org",
        icon: "FaPeopleGroup",
        logo: false,
        logoUrl: "",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Soigner la mort",
        title: "La mort à vivre",
        url: "https://www.seuil.com/ouvrage/la-mort-a-vivre-catherine-vincent/9782021465679",
        icon: "FcReading",
        logo: false,
        logoUrl: "",
        logoLabel: "Livre, article",
    },
    {
        theme: "Soigner la mort",
        title: "La maison de Gardanne",
        url: "https://lamaisondegardanne.org",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://lamaisondegardanne.org/wp-content/uploads/2020/11/logo-80.png.jpg",
        logoLabel: "Lieu",
    },
    {
        theme: "Soigner la mort",
        title: "La maison Jeanne Garnier",
        url: "https://jeanne-garnier.org",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://jeanne-garnier.org/wp-content/uploads/2023/06/logo.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Soigner la mort",
        title: "Coopérative funéraire de Rennes",
        url: "https://www.lacoopfunerairederennes.fr",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://www.lacoopfunerairederennes.fr/wp-content/themes/fasterthanlight/img/logo.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Soigner la mort",
        title: "Collectif National des Maisons de Vie",
        url: "https://www.cndmv.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl: "https://www.cndmv.fr/wp-content/uploads/2023/04/cnmdv-1.jpg",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Soigner la mort",
        title: "Convention citoyenne fin de vie",
        url: "https://conventioncitoyennesurlafindevie.lecese.fr",
        icon: "FcReading",
        logo: true,
        logoUrl:
            "https://conventioncitoyennesurlafindevie.lecese.fr/sites/cfv/themes/custom/cfv/logo.svg",
        logoLabel: "Livre, article",
    },
    {
        theme: "Soin des soignant·es",
        title: "Soin aux professionnels de Santé",
        url: "https://www.asso-sps.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://cdn.prod.website-files.com/60d9de91c645ba74c0496d69/60d9de91c645bad48a4970e0_Logos-SPS-2020.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Soin des soignant·es",
        title: "Association MOTS",
        url: "https://www.association-mots.org",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://www.association-mots.org/wp-content/uploads/2021/02/LOGO.svg",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Soin des soignant·es",
        title: "Soins aux étudiants",
        url: "https://www.soins-aux-etudiants.com",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://cdn.prod.website-files.com/601ab989590879afe61d66df/603e01c88b0b9d3dc1f129c0_Capture%20d’écran%202021-03-02%20à%2010.11.30-p-500.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Soin des soignant·es",
        title: "LIPSEIM",
        url: "https://www.lipseim.fr",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://www.lipseim.fr/wp-content/uploads/2020/12/Logo-Lipseim-Sd-detour.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Soin des soignant·es",
        title: "Ikaïros",
        url: "https://www.instagram.com/ikairos_culturedusoin/",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://www.fonds-nouveau-monde.org/wp-content/uploads/resized/2024/09/logo-ikairos-250x250-c-default.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Soin des soignant·es",
        title: "CAP CNV Santé",
        url: "https://capcnvsante.com",
        icon: "FaPeopleGroup",
        logo: true,
        logoUrl:
            "https://capcnvsante.com/wp-content/uploads/elementor/thumbs/CAPCNVSante_Logo_Hero-p0vfyrh93bx2qbl0ivvv9qa0cyfksi8ng8p8j9p9y8.png",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Soin des soignant·es",
        title: "Soigner en conscience",
        url: "https://soigner-en-conscience.be/",
        icon: "FaPeopleGroup",
        logo: false,
        logoUrl: "",
        logoLabel: "Collectif, association",
    },
    {
        theme: "Soin des soignant·es",
        title: "Les Bazars de la santé",
        url: "https://www.lesbazarsdelasante.fr",
        icon: "BsHouseFill",
        logo: true,
        logoUrl:
            "https://www.lesbazarsdelasante.fr/wp-content/uploads/2023/11/logo-bazars.png",
        logoLabel: "Lieu",
    },
    {
        theme: "Soin des soignant·es",
        title: "DIU Soigner les soignants",
        url: "http://diu-soignerlessoignants.fr",
        icon: "IoIosSchool",
        logo: false,
        logoUrl: "",
        logoLabel: "Formation, école",
    },
];
