import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';

const MapModal = ({ isOpen, onClose, children }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.body.style.touchAction = 'none';
        }
        return () => {
            document.body.style.overflow = 'unset';
            document.body.style.touchAction = 'auto';
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return createPortal(
        <div 
            className="fixed inset-0 z-[9999] bg-black bg-opacity-75 transition-all duration-300"
            style={{ 
                position: 'fixed', 
                top: 0, 
                left: 0, 
                right: 0, 
                bottom: 0,
            }}
        >
            {/* Close button */}
            <button
                onClick={onClose}
                className="absolute right-4 top-4 z-[10000] rounded-full bg-white p-2 text-black shadow-lg"
                aria-label="Close modal"
            >
                <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                    />
                </svg>
            </button>

            {/* Modal content - always landscape */}
            <div 
                className="flex h-screen w-screen items-center justify-center overflow-hidden bg-white"
                style={{
                    width: '100vh',
                    height: '100vw',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%) rotate(90deg)',
                    transformOrigin: 'center center',
                }}
            >
                {children}
            </div>
        </div>,
        document.body
    );
};

export default MapModal; 